<template>
    <page title="支付" :hasBack="true" textAlign="center" :hasPadding="false">
        <div class="service-buy-page">
            <div class="content">
                <img class="bg" src="@/assets/images/serviceBuy/bg.png" alt="" />
                <div class="price-wrapper">
                    <div class="price-msg">
                        <span>¥</span>
                        <span class="price">{{ price.toFixed(2) }}</span>
                    </div>
                </div>
            </div>
            <div class="pay-list">
                <van-radio-group v-model="payType">
                    <div class="pay-item" :class="{ active: payType === '1' }">
                        <div class="icon-wrapper">
                            <img class="icon" src="@/assets/images/serviceBuy/wechat.png" alt="" />
                            <span>微信支付</span>
                        </div>
                        <van-radio name="1" checked-color="#FF467F" />
                    </div>
                    <div class="pay-item" :class="{ active: payType === '2' }">
                        <div class="icon-wrapper">
                            <img class="icon" src="@/assets/images/serviceBuy/wallet.png" alt="" />
                            <span>余额（剩余￥{{ balance.toFixed(2) }}）</span>
                        </div>
                        <van-radio name="2" checked-color="#FF467F" />
                    </div>
                </van-radio-group>
            </div>
            <div class="pay-btn">
                <van-button class="confirm-btn" round type="primary"
                    >{{ payType === '1' ? '微信' : '零钱' }}支付￥{{ price.toFixed(2) }}</van-button
                >
            </div>
        </div>
    </page>
</template>
<script>
import Page from '@/components/page/Page';
import { ref } from 'vue';
import { Button, Radio, RadioGroup } from 'vant';

export default {
    name: 'ServiceIntroduce',
    components: {
        [Page.name]: Page,
        [Button.name]: Button,
        [RadioGroup.name]: RadioGroup,
        [Radio.name]: Radio,
    },
    setup() {
        const price = ref(20);
        const payType = ref('1');
        const balance = ref(0);
        return {
            price,
            payType,
            balance,
        };
    },
};
</script>
<style lang="less" scoped>
.service-buy-page {
    height: 100%;
    .content {
        position: relative;
        .bg {
            width: 100vw;
        }
        .price-wrapper {
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
        .price-msg {
            display: flex;
            align-items: flex-end;
            justify-content: center;
            margin-top: 53px;
            span {
                font-size: 23px;
                font-family: PingFang SC, serif;
                font-weight: bold;
                line-height: 32px;
                color: #ffffff;
            }
            .price {
                font-size: 46px;
                line-height: 40px;
                margin-left: 3px;
            }
        }
    }
    .pay-list {
        border-radius: 5px;
        background: #ffffff;
        transform: translate(0, -50%);
        margin: 0 15px;
        .pay-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 10px;
            box-sizing: border-box;
            height: 50px;
            .icon {
                width: 26px;
                margin-right: 9px;
            }
            &.active {
                span {
                    color: #ff467f;
                }
            }
        }
        .icon-wrapper {
            display: flex;
            align-items: center;
            span {
                font-size: 15px;
                font-family: PingFang SC, serif;
                color: #808080;
            }
        }
    }
    .pay-btn {
        position: absolute;
        bottom: 62px;
        width: 100%;
        left: 0;
        text-align: center;
    }
}
</style>
